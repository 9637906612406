<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="protocolForm"
               label-width="140px"
               :rules="rules" ref="protocolForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="protocolForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gangwei">
          <el-input v-model="protocolForm.gangwei" placeholder="请填写岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="隶属部门" prop="lishubumen">
          <el-input v-model="protocolForm.lishubumen" placeholder="请填写隶属部门"></el-input>
        </el-form-item>
        <el-form-item label="直接上级" prop="zhijieshangji">
          <el-select v-model="protocolForm.zhijieshangji" placeholder="请选择直接上级" class="w-all">
            <el-option
                v-for="item in superior"
                :key="item.id"
                :label="item.xingming"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="签署劳动合同日期" prop="contractSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.contractSignDate"
                          type="date"
                          placeholder="请选择签署劳动合同日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="合同到期日期" prop="contractEndDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.contractEndDate"
                          type="date"
                          placeholder="请选择合同到期日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="解除劳动合同日期" prop="contractTerminationDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.contractTerminationDate"
                          type="date"
                          placeholder="请选择解除劳动合同日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="离职工资发放月份" prop="resignSalaryReleaseMonth">

          <el-input type="number" v-model="protocolForm.resignSalaryReleaseMonth" placeholder="请填写离职工资发放月份">
            <template slot="append">月份</template>
          </el-input>
        </el-form-item>
        <el-form-item label="签订日期" prop="resignSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.resignSignDate"
                          type="date"
                          placeholder="请选择签订日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('protocolForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载离职协议
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传离职协议反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载离职协议反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {
  departStaffFullTimeAgreement,
  departStaffFullTimeAgreementReply,
  departStaffFullTimeAgreementShow
} from "@/api/resignation";
import {getPageName, zhDate} from "@/utils";
import {downLoadOfferWord, shangJiList} from "@/api/entry";
import {QAHOST, upFile} from "@/config";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";
import {reasonList, yesOrNo} from "@/utils/const";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      superior: [],
      yesOrNo: yesOrNo,
      reasonList: reasonList,
      protocolForm: {
        xingming: '',
        gangwei: '',
        lishubumen: '',
        zhijieshangji: '',
        contractSignDate: '',
        contractEndDate: '',
        contractTerminationDate: '',
        resignSalaryReleaseMonth: '',
        resignSignDate: '',
      },
      rules: {
        xingming: rules.mustInput,
        gangwei: rules.mustInput,
        lishubumen: rules.mustInput,
        zhijieshangji: rules.mustSelect,
        contractSignDate: rules.mustDate,
        contractEndDate: rules.mustDate,
        contractTerminationDate: rules.mustDate,
        resignSalaryReleaseMonth: rules.mustInput,
        resignSignDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "全职员工解除劳动合同", true);
    this.id = Number(this.$route.params.id)
    this.shangJiList()
    this.departStaffFullTimeAgreementShow()
  },
  components: {},
  methods: {
    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },
    async departStaffFullTimeAgreementShow() {
      const res = await departStaffFullTimeAgreementShow(this.id)
      if (res.data) {
        this.protocolForm = {
          xingming: res.data.xingming,
          gangwei: res.data.gangwei,
          lishubumen: res.data.lishubumen,
          zhijieshangji: Number(res.data.zhijieshangji),
          contractSignDate: res.data.ContractSignDate?new Date(res.data.ContractSignDate):'',
          contractEndDate: res.data.ContractEndDate?new Date(res.data.ContractEndDate):'',
          contractTerminationDate: res.data.ContractTerminationDate?new Date(res.data.ContractTerminationDate):'',
          resignSalaryReleaseMonth: res.data.EndSalaryReleaseMonth,
          resignSignDate: res.data.ContractTerminationSignDate?new Date(res.data.ContractTerminationSignDate):'',
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        this.protocolForm.xingming = info.data.staff.xingming
        this.protocolForm.gangwei = info.data.staff.gangwei
        this.protocolForm.lishubumen = info.data.staff.bumen
        this.protocolForm.zhijieshangji = Number(info.data.full_time_staff.zhijieshangji)
        this.protocolForm.contractSignDate = new Date(info.data.full_time_staff.contractSignDate)
        this.protocolForm.contractEndDate = new Date(info.data.full_time_staff.contractEndDate)
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffFullTimeAgreement(
          this.id,
          this.protocolForm.xingming,
          this.protocolForm.gangwei,
          this.protocolForm.lishubumen,
          this.protocolForm.zhijieshangji,
          zhDate(new Date(this.protocolForm.contractSignDate)),
          zhDate(new Date(this.protocolForm.contractEndDate)),
          zhDate(new Date(this.protocolForm.contractTerminationDate)),
          this.protocolForm.resignSalaryReleaseMonth,
          zhDate(new Date(this.protocolForm.resignSignDate)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffFullTimeAgreementShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.protocolForm.xingming + '离职协议.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffFullTimeAgreementReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffFullTimeAgreementShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
